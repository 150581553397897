<template>
  <div class="commentRepresentative">
    <div class="item" v-for="(item, index) in listData" :key="index">
      <div class="item-t">
        <div class="img"><img :src="item.url" alt="" /></div>
        <div class="name">
          <div class="name-t">{{ item.name }}</div>
          <div class="name-b">
            <div
              class="a"
              v-for="(oItem, oIndex) in item.des.split(',')"
              :key="oIndex"
            >
              {{ oItem }}
            </div>
          </div>
        </div>
        <div class="code">
          <div class="txt">代表码</div>
          <div class="qrcode"><img src="./img/code.png" alt="" /></div>
        </div>
      </div>
      <div class="item-b">
        <div class="item-b-box">
          <div class="item-bl">履职承诺</div>
          <div class="item-bc"></div>
          <div class="item-br">{{ item.remark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
export default {
  name: "commentRepresentative",
  data() {
    return {
      listData: [
        {
          url: require("./img/people1.png"),
          name: "毛爱飞",
          des: "宁波市第十六届人大代表,集士港镇井亭社区党支部书记,居委会主任",
          remark: "倾听民声传民意 勇担使命开新局",
        },
        {
          url: require("./img/people2.png"),
          name: "陆意祥",
          des: "海曙区第十二届人大代表,宁波华晟轻工集团有限公司董事长",
          remark: "不忘初心 不辞微小  充分发挥代表作用",
        },
        {
          url: require("./img/people3.png"),
          name: "李莉",
          des: "海曙区第十二届人大代表,宁波甬嘉恒业集团有限公司董事长",
          remark: "积极承担社会责任  彰显人大代表担当",
        },
        {
          url: require("./img/people4.png"),
          name: "张建光",
          des: "集士港镇第十七届人大代表,集士港镇董家桥村支书",
          remark: "忠诚履行代表职责  积极展示代表风范",
        },
        {
          url: require("./img/people5.png"),
          name: "张慧",
          des: "集士港镇第十七届人大代表,退休人员",
          remark: "念群众之所忧  行群众之所盼",
        },
        {
          url: require("./img/people6.png"),
          name: "沈建荣",
          des: "集士港镇第十七届人大代表,集士港镇井亭社区,党支部副书记",
          remark: "建言建策 勤勉尽责,谋福祉 解难题",
        },
        {
          url: require("./img/people7.png"),
          name: "孟伊涵",
          des: "集士港镇第十七届人大代表,集士港镇董家桥村出纳",
          remark: "廉洁秉公 情系群众,尽自己所能 服务村民所求",
        },
      ],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.commentRepresentative {
  min-height: 100vh;
  padding: 20px 32px;
  .item {
    padding: 24px 20px 0;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    margin-bottom: 20px;
    .item-t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      .img {
        width: 114px;
        height: 168px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        .name-t {
          font-weight: 700;
          font-size: 32px;
          color: #323334;
          line-height: 44px;
          margin-bottom: 18px;
        }
        .name-b {
          .a {
            font-weight: 400;
            font-size: 24px;
            color: #ff853a;
            line-height: 34px;
          }
        }
      }
      .code {
        flex-shrink: 0;
        .txt {
          font-weight: 500;
          font-size: 24px;
          color: #ff853a;
          line-height: 34px;
          margin-bottom: 10px;
          text-align: center;
        }
        .qrcode {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .item-b {
      padding: 0 38px;
      .item-b-box {
        padding: 12px 0;
        border-top: 2px solid #ebebeb;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 400;
        font-size: 24px;
        color: #1a1c34;
        line-height: 34px;
        .item-bl {
          width: 156px;
        }
        .item-bc {
          width: 2px;
          height: 51px;
          background: #ebebeb;
        }
        .item-br {
          flex: 1;
          padding-left: 50px;
        }
      }
    }
  }
}
</style>
